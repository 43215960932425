<template>
	<div class="label-list-wrapper">
		<ul v-if="list.length" class="label-list">
			<li
				v-for="item in list"
				:key="item.id"
				:class="['label-item',{ 'badge-item' : type === PRODUCT_LABEL_TYPES.badge }]"
			>
				<ModalProductLabelListItem
					:data="item"
					:type="type"
					@onSelect="(data) => $emit('onSelect', data)"
				/>
			</li>
		</ul>
		<div v-else class="h-100 d-flex flex-column align-items-center justify-content-center">
			<img
				src="/assets/images/product-placeholder.svg"
				alt="Empty image"
				width="64"
				class="empty-image"
			>
			<h6 class="mb-1">
				{{ $t("global.searchNotFound", { field: "labels" }) }}
			</h6>
		</div>
		<div v-if="badgeList.length" class="default-badge-list">
			<hr>
			<label for="badge-item">Badge type</label>
			<ul id="badge-item" class="label-list">
				<li
					v-for="item in badgeList"
					:key="item.id"
					:class="['label-item', 'badge-item']"
				>
					<ModalProductLabelListItem
						:data="item"
						:type="PRODUCT_LABEL_TYPES.badge"
						@onSelect="(data) => $emit('onSelect', data)"
					/>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import ModalProductLabelListItem from '@/components/ModalProductLabelListItem.vue';
import {
	PRODUCT_LABEL_TYPES,
} from '../enums/productLabels';

export default {
	name: 'ModalProductLabelList',

	components: {
		ModalProductLabelListItem,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		badgeList: {
			type: Array,
			default: () => [],
		},
		type: {
			type: String,
			default: PRODUCT_LABEL_TYPES.primary,
		},
	},
	data() {
		return {
			PRODUCT_LABEL_TYPES,
		};
	},
};
</script>

<style lang="scss" scoped>
	.label-list-wrapper {
		height: rem(332);
		padding: rem(24);
		overflow-y: auto;
	}

	.label-list {
		padding: 0;
		margin: rem(-8);
		list-style: none;
	}

	.label-item {
		max-width: 16.6666666667%;
		display: inline-block;
		padding: rem(8);
	}

	.badge-item {
		max-width: unset;
	}

	.empty-image {
		margin-bottom: rem(12);
	}

	.default-badge-list {
		label {
			@include typo-body-1;
		}
	}
</style>
