<template>
	<div :class="['label-list-item', { 'is-active': data.isSelected }, { 'badge-item': type === PRODUCT_LABEL_TYPES.badge }]">
		<ProductLabelBadge v-if="type === PRODUCT_LABEL_TYPES.badge" :label="data.titleEN" :color="data.fontColor" />
		<template v-else>
			<img :src="data.thumbnail" :alt="data.name">
		</template>
		<div class="label-list-item-checkbox">
			<CInputCheckbox
				:data-test-id="data.name"
				:checked="data.isSelected"
				class="checkbox-color-banana"
				custom
				@input="$emit('onSelect', data)"
			/>
		</div>
	</div>
</template>

<script>
import ProductLabelBadge from '@/components/ProductLabelBadge.vue';
import {
	PRODUCT_LABEL_TYPES,
} from '../enums/productLabels';

export default {
	name: 'ModalProductLabelListItem',

	components: {
		ProductLabelBadge,
	},

	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
		type: {
			type: String,
			default: PRODUCT_LABEL_TYPES.primary,
		},
	},

	data() {
		return {
			PRODUCT_LABEL_TYPES,
		};
	},
};
</script>

<style lang="scss" scoped>
	.label-list-item {
		position: relative;
		z-index: 1;
		width: rem(80);
		height: rem(80);
		background-color: $color-white;
		border: 1px solid $color-gray-300;
		border-radius: 4px;
		overflow: hidden;
		cursor: pointer;

		&.type-secondary {
			width: rem(176);
		}

		// overlay
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			width: 100%;
			height: 100%;
			background-color: $color-black;
			opacity: 0;
			transition: opacity $transition-duration;
		}

		&:not(.is-active):hover::before {
			opacity: 0.08;
		}

		// active
		&.is-active {
			border-color: $color-primary;

			// overlay with active
			&::before {
				background-color: $color-primary;
				opacity: 0.15;
			}
		}

		// image
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}

		// .media-list-item-checkbox
		&-checkbox {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 3;

			// overwrite
			::v-deep {
				.custom-control-label {
					margin-left: 0;

					&::before {
						border-color: $color-gray-400;
					}
				}
			}
		}
	}

	.badge-item {
		display: inline-flex;
		gap: rem(8);
		padding: rem(8);
		width: 100%;
		min-width: rem(80);
		height: fit-content;

		.label-list-item-checkbox {
			position: unset;
		}
	}
</style>
