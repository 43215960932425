<template>
	<div :class="`label-container type-${type}`" :data-test-id="`label-container-type-${type}`">
		<CModal
			:show.sync="isShow"
			:close-on-select="false"
			:title="`Select ${title} label`"
			class="modal-label"
			centered
		>
			<template #default>
				<div class="flex-fill">
					<div class="search-area d-flex px-4">
						<ModalProductLabelSearch
							v-model.trim="keyword"
							@onSubmit="handleSearch"
						/>
					</div>
					<BaseLoading
						v-if="isLoading"
						class="loading d-flex align-items-center"
					/>
					<ModalProductLabelList
						v-else
						:list="transformedList"
						:badge-list="badgeLabelList "
						:type="type"
						@onSelect="handleSelectListItem"
					/>
				</div>
			</template>
			<template #footer>
				<ModalProductLabelFooter
					:selected-labels="selectedLabels"
					@onClose="close"
					@onSubmit="handleSubmit"
				/>
			</template>
		</CModal>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ModalProductLabelSearch from '@/components/ModalProductLabelSearch.vue';
import ModalProductLabelList from '@/components/ModalProductLabelList.vue';
import ModalProductLabelFooter from '@/components/ModalProductLabelFooter.vue';
import {
	PRODUCT_LABEL_TYPES,
	PRODUCT_LABEL_SUB_TYPES,
	PRODUCT_LABEL_SUB_TYPE_LABELS,
} from '../enums/productLabels';

export default {
	name: 'ModalProductLabel',

	components: {
		ModalProductLabelSearch,
		ModalProductLabelList,
		ModalProductLabelFooter,
	},
	props: {
		singleSelect: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			PRODUCT_LABEL_TYPES,
			PRODUCT_LABEL_SUB_TYPES,
			PRODUCT_LABEL_SUB_TYPE_LABELS,
			isShow: false,
			selectedLabels: [],
			type: PRODUCT_LABEL_TYPES.primary,
			subType: '',
			keyword: null,
		};
	},

	computed: {
		...mapState('productLabels', {
			list: 'list',
		}),
		...mapGetters({
			primaryLabels: 'productLabels/getPrimaryLabels',
			secondaryLabels: 'productLabels/getSecondaryLabels',
			frameLabels: 'productLabels/getFrameLabels',
			badgeLabels: 'productLabels/getBadgeLabels',
		}),

		isLoading() {
			return this.list.isLoading;
		},
		labelList() {
			switch (this.type) {
				case PRODUCT_LABEL_TYPES.primary:
				case PRODUCT_LABEL_TYPES.primaryBadge:
					return this.primaryLabels;
				case PRODUCT_LABEL_TYPES.frame:
					return this.frameLabels;
				case PRODUCT_LABEL_TYPES.secondary:
					return this.secondaryLabels;
				case PRODUCT_LABEL_TYPES.badge:
					return this.badgeLabels;
				default:
					return null;
			}
		},
		// LIST FOR RENDER BADGE WHEN ADMIN COME-IN DEFAULT LABEL TAB
		badgeLabelList() {
			if (this.type === PRODUCT_LABEL_TYPES.primaryBadge) {
				return this.badgeLabels?.map((listItem) => ({
					...listItem,
					isSelected: this.selectedLabels.some((label) => label.id === listItem.id),
				})) || [];
			}
			return [];
		},
		transformedList() {
			return this.labelList.map((listItem) => ({
				...listItem,
				isSelected: this.selectedLabels.some((label) => label.id === listItem.id),
			}));
		},
		title() {
			if (this.subType) {
				const subTypeKey = Object.keys(PRODUCT_LABEL_SUB_TYPES).find((key) => PRODUCT_LABEL_SUB_TYPES[key] === this.subType);

				return PRODUCT_LABEL_SUB_TYPE_LABELS[subTypeKey];
			}

			return this.type;
		},
	},
	methods: {
		...mapActions({
			getProductLabels: 'productLabels/getProductLabels',
		}),

		async open(type = PRODUCT_LABEL_TYPES.primary, subType = null, selectedLabels = []) {
			// Clear data when open modal
			this.isShow = true;
			this.type = type;
			this.subType = subType;
			this.keyword = null;
			this.selectedLabels = selectedLabels;

			// GET 2 TYPE FOR DEFAULT LABEL TAB
			if (type === PRODUCT_LABEL_TYPES.primaryBadge) {
				await this.getProductLabels({ type: PRODUCT_LABEL_TYPES.primary, per_page: 'all' });
				await this.getProductLabels({ type: PRODUCT_LABEL_TYPES.badge, per_page: 'all' });
			} else {
				// NORMAL FLOW GET 1 TYPE
				await this.getProductLabels({ type: this.type, per_page: 'all' });
			}
		},
		close() {
			this.isShow = false;
		},
		handleSelectListItem(data = {}) {
			const id = data.id;
			const isExistLabel = this.selectedLabels.some((label) => label.id === id);

			if (this.singleSelect) {
				this.selectedLabels = [data];
			} else if (isExistLabel) {
				this.selectedLabels = this.selectedLabels.filter((value) => value.id !== id);
			} else {
				this.selectedLabels = [...this.selectedLabels, data];
			}
		},
		handleSubmit() {
			this.$emit('onSubmit', {
				labels: this.selectedLabels,
				type: this.type,
				subType: this.subType,
			});
			this.close();
		},
		async handleSearch() {
			await this.getProductLabels({
				q: this.keyword,
				type: this.type,
				per_page: 'all',
			});
		},
	},
};
</script>

<style lang="scss" scoped>
	.modal-label {
		::v-deep .modal-dialog {
			max-width: rem(800);

			.modal-content {
				min-height: rem(492);
			}

			.modal-body {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	.type-secondary {
		::v-deep .modal-dialog {
			.label-item {
				max-width: 25%;
			}

			.label-list-item {
				width: rem(176);
			}
		}
	}

	.loading {
		min-height: rem(312);
	}

	.search-area {
		border-bottom: 1px solid $color-gray-300;
	}
</style>
