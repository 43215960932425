<template>
	<span class="badge-label" :style="fontColorValue">
		{{ labelText }}
	</span>
</template>

<script>

export default {
	name: 'ProductLabelBadge',
	props: {
		label: {
			type: String,
			default: '',
		},
		color: {
			type: String,
			default: '#000000',
		},
	},
	computed: {
		fontColorValue() {
			return this.color && ({ color: this.color });
		},
		labelText() {
			if (this.label) {
				return this.label.toUpperCase();
			}
			return this.label;
		},
	},
};
</script>

<style lang="scss" scoped>
	.badge-label {
		font-weight: bold;
	}
</style>
