<template>
	<div class="modal-label-footer">
		<span
			class="typo-body-1 mr-4 selected-info"
			:class="{ 'color-black-45': selectedLabels.length === 0 }"
		>
			<template v-if="selectedLabels.length">{{ selectedLabels.length }} Selected</template>
			<template v-else>Please select at least 1 label</template>
		</span>
		<CButton
			color="tertiary"
			class="mr-3 -min-width"
			@click="$emit('onClose')"
		>
			Cancel
		</CButton>
		<CButton
			:disabled="!selectedLabels.length"
			color="primary"
			class="-min-width"
			@click="$emit('onSubmit')"
		>
			Confirm
		</CButton>
	</div>
</template>

<script>
export default {
	name: 'ModalProductLabelFooter',

	props: {
		selectedLabels: {
			type: Array,
			default: () => [],
		},
	},
};
</script>
