<template>
	<form
		class="flex-fill"
		@submit.prevent="$emit('onSubmit')"
	>
		<CInput
			:value="value"
			data-test-id="search-input"
			type="text"
			placeholder="Search by label name"
			@input="(value) => $emit('input', value)"
		>
			<template #prepend-content>
				<button
					data-test-id="label-preview-filter-button"
					class="btn-search"
					type="submit"
				>
					<FontAwesomeIcon :icon="['far', 'search']" />
				</button>
			</template>
		</CInput>
	</form>
</template>

<script>
export default {
	name: 'ModalProductLabelSearch',
	props: {
		value: {
			type: String,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
.btn-search {
	display: inline-flex;
	background: transparent;
	box-shadow: none;
	border: none;
	padding: 0;

	&:focus {
		outline: none;
	}
}
</style>
